import consumer from "./consumer"

consumer.subscriptions.create(
    {channel: "PaymentStatusChannel"},
    {
        connect() {
        },
        received(data) {
            $('.pending_status_' + data['id']).each(function () {
                $(this).hide();
            });
            $('.' + data['status'] + '_status_' + data['id']).each(function () {
                $(this).show();
            });
            setTimeout(call_back_url, 1000);
        }
    }
);